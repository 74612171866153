import React from "react";
import { observer, inject } from "mobx-react";
import { Navigate } from "react-router-dom";

import "./Login.scss";

import { toastFail, toastSuccess } from "utils/Toast";

import loginLogo from "./login-logo.png"

type LoginProps = {
    AuthStore?: any;
};

function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
}

async function successfunction() {
    toastSuccess("Password reset successfully");
        await timeout(3000);
        window.location.href = "/login";
}

class ResetPassword extends React.Component<LoginProps, any> {
    static state = {
        email: "",
        token: "",
        new_password:""
    };

  

    sendEmailFail = () => {
        toastFail("Email didn't send successfully");
    };

    sendEmailSuccess = () => {
        toastSuccess("Email sent successfully");
    };

    resetPasswordFail = () => {
        toastFail("Invalid email or token");
    };

    resetPasswordSuccess = () => {
        successfunction();
    };

    attemptSendEmail = (e: any) => {
        const { AuthStore } = this.props;

        e.preventDefault();

        AuthStore.sendEmail(this.state.email).then(this.sendEmailSuccess).catch(this.sendEmailFail);
    };

    attemptResetPassword = (e: any) => {
        const { AuthStore } = this.props;

        e.preventDefault();

        AuthStore.resetPassword(this.state).then(this.resetPasswordSuccess).catch(this.resetPasswordFail);
    };

    updateEmail = (e: any) => {
        this.setState({ email: e.target.value });
    };

    updateToken = (e: any) => {
        this.setState({ token: e.target.value });
    };

    updatePassword = (e: any) => {
        this.setState({ new_password: e.target.value });
    };

    render() {
        const { AuthStore } = this.props;

        if (AuthStore.isAuthenticated) {
            return <Navigate replace to="/" />;
        }

        return (
            <section id="Login">
                <form>
                    {/* <div> */}
                    <img className="logo" src={loginLogo}  alt="Logo" />
                    <h1 className="login-welcome">Reset Password</h1>
                    {/* </div> */}
                    <fieldset>
                        {/* <legend>Sign in</legend> */}
                        <div className="username">
                            {/* <label>Email:</label> */}
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z" />
                            </svg>
                            <input
                                className="user-input"
                                type="email"
                                id="login-email"
                                placeholder="email"
                                onChange={this.updateEmail}
                            />
                        </div>
                        <button type="button" className="send-email-button" onClick={this.attemptSendEmail}>
                        Send Email
                    </button>
                        <div className="password">
                            {/* <label>Password:</label> */}
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path d="M80 192V144C80 64.47 144.5 0 224 0C303.5 0 368 64.47 368 144V192H384C419.3 192 448 220.7 448 256V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V256C0 220.7 28.65 192 64 192H80zM144 192H304V144C304 99.82 268.2 64 224 64C179.8 64 144 99.82 144 144V192z" />
                            </svg>
                            <input
                                className="pass-input"
                                type="text"
                                id="login-password"
                                placeholder="token"
                                onChange={this.updateToken}
                            />
                        </div>
                        <div className="password">
                            {/* <label>Password:</label> */}
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path d="M80 192V144C80 64.47 144.5 0 224 0C303.5 0 368 64.47 368 144V192H384C419.3 192 448 220.7 448 256V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V256C0 220.7 28.65 192 64 192H80zM144 192H304V144C304 99.82 268.2 64 224 64C179.8 64 144 99.82 144 144V192z" />
                            </svg>
                            <input
                                className="pass-input"
                                type="password"
                                id="login-password2"
                                placeholder="New Password"
                                onChange={this.updatePassword}
                            />
                        </div>
                        <button type="button" className="login-button" onClick={this.attemptResetPassword}>
                        Reset Password
                    </button>
                    </fieldset>

                    

                    {/* <div className="create-user">
                        <a href="newuser">Create User</a>
                    </div> */}
                </form>
            </section>
        );
    }
}

export default inject("AuthStore")(observer(ResetPassword));